$(document).ready(function() {

	// 移动端响应式
	function reset() {
		var width = 640;//设置默认最大宽度
		var fontSize = 100;//默认字体大小
		var widthProportion = function () {
			var p = (document.body && document.body.clientWidth || document.getElementsByTagName("html")[0].offsetWidth) / width;
			return p > 1 ? 1 : p < 0.5 ? 0.5 : p;
		};
		var changePage = function () {
			document.getElementsByTagName("html")[0].setAttribute("style", "font-size:" + widthProportion() * fontSize + "px !important");
		}.bind(this);

		changePage();
		window.addEventListener('resize', function () {
			changePage();
		}, false);
	}
	reset();
	
	// 设备监测
	var societyUrl = '';		// 社会招聘地址	
	var campusUrl = '';			// 校园招聘地址
	function equipmentMonitor() {
		if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
			societyUrl = "https://app.mokahr.com/apply/qingcishuma/41125";
			campusUrl = 'https://app.mokahr.com/m/campus_apply/qingcishuma/41126';
		} else {
			societyUrl = "https://app.mokahr.com/m/apply/qingcishuma/41125";
			campusUrl = 'https://app.mokahr.com/campus_apply/qingcishuma/41126';
		}
		
		$('.society').attr('href', societyUrl);
		$('.campus').attr('href', campusUrl);
	} equipmentMonitor();



	$('.nav_btn').click(function() {
		$('.mo_nav_btn').removeClass('mo_nav_btn_on');
		$('.nav_list').fadeOut();
		var navMore = $('.pop_nav_warp');
		if (navMore.hasClass('hide')) {
			$('.pop_nav_warp').removeClass('hide')
		} else {
			$('.pop_nav_warp').addClass('hide')
		}
	});

	// 首页顶部轮播图
    var com_swiper_head = new Swiper('.banner1 .swiper-container',{
        resistanceRatio:0,
        on:{
            init: function(){
                swiperAnimateCache(this);
                swiperAnimate(this); 
            }, 
            slideChangeTransitionEnd: function(){ 
                swiperAnimate(this);
                $('.banner1_btn ul li').eq(this.realIndex).addClass('active').siblings().removeClass('active');
            }
        },
        autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
        	disableOnInteraction: true,
        }
        ,
        effect: 'fade'

    });

    $('.banner1_btn ul li').on('mouseenter',function(){
        $('.banner1_btn ul li').eq($(this).index()).addClass('active').siblings().removeClass('active');
        com_swiper_head.slideTo($(this).index());
    })

    // 页尾浏览器升级提示
	if(!!window.ActiveXObject || "ActiveXObject" in window){
		$('body').append('<div style="width:100%;height:50px;background:#fff;color:#000;border-bottom:1px	solid #fff;text-align:center;line-height:50px;border-top:1px solid #cfcfcf;font-size:16px;position:fixed;bottom:0;z-index: 9999">您使用的浏览器版本过低，可能会影响到您浏览本页面，建议升级您的浏览器：  <a href="http://www.google.cn/chrome/browser/desktop/" style="padding:15px; background:#c81331; color:#fff;" target="_black">谷歌 Chrome</a></div>').css('padding-bottom','50px');
	};
		
	//导航横线位置
	$('.nav-top .cont li').each(function(i,e){
		if($(e).hasClass('active')){
			$('.nav-top .cont i').css('left',$(e).index()*($('.nav-top .cont li').width()+parseInt($('.nav-top .cont li').css('margin-right'))));
		};
	})
	$('body')
	//导航
	.on('mouseenter','.nav-top .cont li,.nav-top .language',function(){
		var self=$(this);
		self.addClass('active').siblings().removeClass('active');
		$('.nav-top .cont i').stop().animate({
			'left':self.index()*($('.nav-top .cont li').width()+parseInt($('.nav-top .cont li').css('margin-right')))
		});
		testAnim($('.nav-more'),'fadeInDown','fadeOutUp');
	})
	.on('mouseleave','.nav',function(){
		testAnim($('.nav-more'),'fadeOutUp','fadeInDown');
	});
	
	fadeInUpFn();
		
	//补间动画
	function testAnim(obj,active,old) {
	    obj.removeClass(old + ' animated2').addClass(active + ' animated2');
	};

	//向上飘动效果
	function fadeInUpFn(){
		var arr=$('.fadeInUpFn');
		$(window).scroll(function() {
			fn();
		})
		fn();
		function fn(){
			arr.each(function(i,e){
				$(e).css('opacity',0);
				var wTop = $(window).scrollTop();
		        var objTop = $(e).offset().top;
		        if(objTop - wTop < $(window).height()-200){
					$(e).addClass('fadeInUp animated');
				}
			});
		}
	};

	$('.mo_nav_btn').click(function() {
		var _this = $(this);
		$('.pop_nav_warp').addClass('hide');
		if (_this.hasClass('mo_nav_btn_on')) {
			_this.removeClass('mo_nav_btn_on')
			$('.nav_list').fadeOut(0);
		} else {
			$('.nav_list').fadeIn(0);
			_this.addClass('mo_nav_btn_on')
		}
	});	

});

// wow动画
function wowAni() {
    var wow = new WOW({ 
        boxClass: 'wow', 
        animateClass: 'animated', 
        offset: 0, 
        mobile: true, 
        live: true 
    }); 
    wow.init();
}

var date = {
    isDuringDate: function (beginDateStr, endDateStr) {
        var curDate = new Date(),
            beginDate = new Date(beginDateStr),
            endDate = new Date(endDateStr);
        if (curDate >= beginDate && curDate <= endDate) {
            $('body').css({
                '-webkit-filter': 'grayscale(1)',
                '-moz-filter': 'grayscale(1)',
                '-mz-filter': 'grayscale(1)',
                'filter': 'grayscale(1)'
            });
            return true;
        }
        $('body').css({
            '-webkit-filter': 'grayscale(0)',
            '-moz-filter': 'grayscale(0)',
            '-mz-filter': 'grayscale(0)',
            'filter': 'grayscale(0)'
        });
    }
};

date.isDuringDate('2020/04/04', '2020/04/05');